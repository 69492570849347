@import "./assets/vendor/unity/css/em-unity-1.7.0.min.css";

.tcdb-cursor-pointer {
    cursor: pointer;
}

.tcdb-text-primary-color {
    color: rgb(255, 255, 255);
}

.redast {
  color: red;
  font-weight: bold;
}
